const firebase = {
  apiKey: 'AIzaSyC7fddeOJt58AA_FVbMvuarbHFB5LhDaL4',
  authDomain: 'mirage-api-stag.firebaseapp.com',
  databaseURL: 'https://mirage-api-stag.firebaseio.com',
  projectId: 'mirage-api-stag',
  storageBucket: 'mirage-api-stag.appspot.com',
  messagingSenderId: '',
};

const baseUrl = 'https://stag.api.mirageid.com';
const websiteUrl = 'https://stag.mirageid.com';
const docUrl = 'https://stag.docs.mirageid.com';
const helpUrl = 'https://help.mirageid.com/en';

const launchDarkly = {
  clientID: '5dc308f0fec6f208c5830095',
};

const stripeToken = 'pk_test_WUSP5RjTKLLtYI31wHHEAQPZ';

export default {
  firebase,
  baseUrl,
  websiteUrl,
  launchDarkly,
  stripeToken,
  docUrl,
  helpUrl,
};
